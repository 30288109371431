import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Link } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Persist Calculated Value`}</h1>
    <p>{`Is it expensive to calculate?`}</p>
    <ul>
      <li parentName="ul"><Link to="hooks/useMemo" mdxType="Link">Yes</Link></li>
      <li parentName="ul"><Link to="data/cheaply-calculated" mdxType="Link">No</Link></li>
    </ul>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      